@import url(https://fonts.cdnfonts.com/css/aubrey);
@import url(https://fonts.cdnfonts.com/css/aubrey);
/* //Color Palette// */
/* 
light gray: rgb(223 230 233)
light blue: rgb(183 201 224)
gray: rgb(101 112 129)
steal blue: rgb(47 70 85)
steal: rgb(22 38 46)
orange: rgb(187 146 117) 
*/

@font-face {
  font-family: "Aubrey"; 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot"); 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot?#iefix") 
  format("embedded-opentype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff") format("woff"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.svg#Aubrey") format("svg"); 
}
  *::after,
  *::before{
      margin: 0;
      padding: 0%;
      box-sizing: inherit;
  }
  body{
  font-size: 10;
  }
  body {
     box-sizing: border-box;
      }

body{
  font-family: 'Darker Grotesque', sans-serif;
}

.App {
  text-align: center;
}

.navLink{
  text-align: center;
}
.button{
  color: rgb(22 38 46) !important;
  display: -webkit-inline-flex;
  display: inline-flex;
  background-color: rgb(183 201 224)!important;
  border: none!important;
  border-radius: 5px;
  margin-top: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  padding: 5px;
  font-size: 1.3em!important;
}
.button:hover{
  background-color: rgb(22 38 46) !important;
  color: white !important;
}
.header_btn_div{
  display: inline-block;
}
#header_btn{
  text-decoration: none;
  margin: 5px;
}
.smicon{
  position: absolute !important;
  right: 10%;
}

.App-header {
  background-color: rgb(22 38 46);
  background: url("https://res.cloudinary.com/legz444/image/upload/v1627919448/skin_collective/41bf88cd-7880-4bb1-9644-f356529eb003_zceowy.jpg") center center no-repeat;
  /* background-size: cover; */
  /* min-height: 100vh; */
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(47 70 85);
  vertical-align: top;
  -webkit-animation: breath 60s linear infinite;
          animation: breath 60s linear infinite;
}

.overlay Img{
  width: 200px;
  height: 200px;
}
.mission{
  -webkit-order: 1;
          order: 1;
  width: 100vw;
  height: 400px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  background-color: rgb(47, 70, 85);
  color: rgb(223 230 233);
  font-family: 'Darker Grotesque', sans-serif;

}
.mission h1{
  margin: auto;
  max-width: 40%;
  font-size: calc(18px + 2vmin);
}
.special_words{
  font-family: "Aubrey"; 
  color: rgb(101 112 129);
}

.Pictures{
  padding-top: 100px;
  -webkit-order: 2;
          order: 2;
  width: 100vw;
  background-color: rgb(223 230 233);
}

.homePage_subquote{
  padding-bottom: 25px;
  text-align: center;
  margin: auto;
  width: 50vw;
  font-family: "Darker Grotesque", sans-serif;

}
.homePage_quote{
  padding-bottom: 50px;
  text-align: center;
  margin: auto;
  width: 50vw;
  font-family: "Darker Grotesque", sans-serif;
}

.serviceMenu_page{
  background-color: rgb(223, 230, 233);
}
.menu{
  background-color: rgb(223, 230, 233);
  background-position: top;
  margin: auto;
  text-align: center;
}
.menu table{
  background-color: rgb(223, 230, 233);
  width: 75vw;
  margin: auto;
  text-align: center;
}

.menu th{
  color:rgb(47, 70, 85);
  font-family: "Aubrey";
  text-align: center;
  padding: 15px;

}
.menu h4{
  color:rgb(47, 70, 85);
  font-family: "Aubrey";
  text-align: center;
  padding: 0;
}

.menu_left{
  width: 40%;
  padding-left: 25px;
  padding-right: 10px;
  font-family: 'Darker Grotesque', sans-serif;
}
.menu_right{
  width: 40%;
  padding-right: 25px;
  padding-left:10px;
  padding-bottom: 15px;
  font-family: 'Darker Grotesque', sans-serif;
}
.menu_center{
padding-left: 25px;
padding-right: 25px;
padding-bottom: 15px;
margin: auto;
font-family: 'Darker Grotesque', sans-serif;
}
.menu .button{
margin-bottom: 75px;
}


.card{
  color:rgb(47, 70, 85)
  /* background-color: rgb(22 38 46) !important; */
}
.card h4{
  padding-top: 10px;
}
.Products{
  background-color: rgb(183 201 224);
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.product-cards{
  width: 100%;
  margin: auto;
  text-align: center;
}
.product_fig{
  padding: 25px !important;
}

.Contact{
  background-color: rgb(223 230 233);
  font-family: 'Darker Grotesque', sans-serif;
  padding-top: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.Modal{
  background-color: rgb(223 230 233);
  color:rgb(47 70 85);
  font-family: 'Darker Grotesque', sans-serif;
}
.close_contact_button{
  background-color: rgb(183 201 224) !important;
  color: rgb(22 38 46) !important;
  border: none !important;
  margin-top: 25px;
  font-family: "Aubrey";
  margin-right: 5px;
  font-size: .8em !important;
}
.send_contact_button{
  background-color: rgb(183 201 224) !important;
  color: rgb(22 38 46) !important;
  border: none !important;
  margin-top: 25px;
  font-family: "Aubrey";
  font-size: .8em !important;
}

.first{
  width: 200px;
  height: 250px;
  top: 125px;
}
.second{
  width: 200px;
}

.imgText{
  background-color: rgba(101, 112, 129, .4);
  width: 300px;
  height: 300px;
  top: 50px;
}
.imgText p{
  font-size: calc(9px + 2vmin);
  font-family: 'Darker Grotesque', sans-serif;
}


.About{
  min-height: 100vh;
  background-color: rgb(223 230 233);
}
.About .p2{
display: none;
}
.About .first{
  background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624753629/skin_collective/pexels-cottonbro-4612151_xd68zg.jpg");
  background-size: cover;
}
.About .second{
  background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624899187/skin_collective/amanda-dalbjorn-fvInY-Gh7sc-unsplash_xgxmym.jpg");
  background-size: cover;
  visibility: hidden;
}
.About .imgText h4{
  padding: 10px;
  font-family: "Aubrey";
  color:rgb(22 38 46);
  text-align: center;
}
.About .imgText{
  color:rgb(22 38 46);

}

.page_title{
  padding-top: 200px;
  padding-bottom: 100px;
  color: rgb(22, 38, 46);
  text-align: center;
  font-family: "Aubrey";
  font-size: calc(12px + 3vmin)
}

.Tegan{
  background-color: rgb(22, 38, 46);
}

/* about page */
.about_page{
  background-color: rgb(223 230 233);
}
.tegan_card{
  border: none !important;
  background-color: rgb(223 230 233) !important;
}
.tegan_title{
  text-align: center;
  font-family: "Aubrey";
  font-size: calc(12px + 2vmin)
}
.tegan_text{
  font-family: "Darker Grotesque", sans-serif;
  font-size: calc(12px + .8vmin)
}
.tegan_text span{
  text-align: center !important;
}
.about_reviews{
  height: 30vh;
  background-color: rgb(22, 38, 46);
  color: white;
  margin: auto;
  font-family: "Darker Grotesque", sans-serif;
  font-size: calc(12px +.5vmin);
}
.about_quote{
  padding: 30px;
  color: white;
}
.carousel_container{
  background-color: rgb(223 230 233);
}


/* .carousel h5{
font-family: "Darker Grotesque", sans-serif;
font-size: 2rem;
} */

.carousel h2{
  font-family: "Aubrey";
  font-size: 4rem;
}

footer{
  padding-top: 50px;
  background-color: rgb(223 230 233);
}
footer p{
  text-align: center;
  font-size: xx-small;
}

/* Media queries */

@media (min-width: 319px){

  .App-header{
    background-size: cover;
    min-height: 100vh;
  }
  .nav{
    visibility: hidden;
  }
  .imgText{
    background-color: rgba(223, 230, 233, .7);
  }
  .About{
    background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624753629/skin_collective/pexels-cottonbro-4612151_xd68zg.jpg");
    background-size: cover;
  }
  .About .first{
    display: none;
  }
  .About .imgText{
    top: 150px;
    width: 90vw;
    height: 55vh;
    left: 5vw;
  }
  .About .imgText p{
    text-align: center;
    padding-bottom: 5px;
    font-size: calc(8px + 2vmin);
    width: 80%;
    margin: auto;
  }
  .carousel{
    width: 100vw;
    padding-bottom: 25px;
  }
  .carousel h2{
    font-size: 1rem;
  }
}
@media (min-width: 400px){
  .App-header{
    background-size: cover;
    min-height: 100vh;
  }
  .About .imgText p{
    font-size: calc(12px + 2vmin);
  }
}


/* // [ sm ] Small devices (landscape phones, 576px and up) */ 
@media (min-width: 565px) {
  .App-header{
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .nav{
    visibility: visible;
  }
  .overlay Img{
    margin-top: 100px;
    width: 300px;
    height: 300px;
  }
  .About{
    background-image: none;
    background-color:rgb(223, 230, 233)
  }
  .About .first{
    display:inherit;
    position: absolute;
    width: 40vw;
    height: 80vh;
    top: 30px;
    left: 5vw;
  }
  .About .imgText{
    top: 10px;
    height: 90vh;
    width: 50vw;
    left: 50vw;

  }
  .About .imgText p{
    font-size: calc(8px + 2vmin);
  }

}
/* // [ md ] Medium devices (tablets, 768px and up) */
@media (min-width: 765px) {




  .About{
    height: 130vh;
    max-height: 800px;
  }
  .About .first{
    top: 15vh;
    height: 70vh;
    width: 50vw;
    left: 3%;
    z-index: 1;
  }
  .About .second{
    visibility: visible;
    height: 40vh;
    width: 50vw;
    right: 5%;
    top: 55vh;
  }
  .About .imgText{
    z-index: 2;
    width: 60vw;
    height: 55vh;
    left: 40%;
    background-color: rgba(47, 70, 85, .8);
    color: white;
    top:10vh;
  }
  .About .imgText h4{
    margin: 15px;
    color: white;
  }
  .About .imgText p{
    font-size: calc(12px + 1.5vmin);
  }
  .carousel h2{
    font-size: 2rem;
  }
  }
@media (min-width: 800px){

  .About .first{
    left: 5%;
    top: 20px;
    width: 40vw;
    height: 90vh;
  }
  .About .imgText{
    top: 25px;
    left: 50%;
    width: 40vw;
    height: 80vh;
  }
  .About .imgText p{
    font-size: calc(10px + 1vmin);
  }
  .About .second{
    visibility: hidden;
  }
  .carousel h2{
    font-size: 3rem;
  }
 }
/* // [ lg ] Large devices (desktops, 992px and up) */
@media (min-width: 900px) {

  @-webkit-keyframes breath {
    0%   { background-size: 100% auto; }
    50% { background-size: 140% auto; }
    100% { background-size: 100% auto; }
  }

  @keyframes breath {
    0%   { background-size: 100% auto; }
    50% { background-size: 140% auto; }
    100% { background-size: 100% auto; }
  }
  .About .first{
    top: 10vh;
    height: 80vh;
    width: 40vw;
    left: 5%;
  }
  .About .second{
    visibility: visible;
    background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624899187/skin_collective/amanda-dalbjorn-fvInY-Gh7sc-unsplash_xgxmym.jpg");
    background-size: cover;
    height: 50vh;
    width: 50vw;
    right: 15%;
    top: 45vh;
  }
  .About .imgText{
    z-index: 1;
    width: 50vw;
    height: 45vh;
    top: 15vh;
    left:40%;
    background-color: rgba(47, 70, 85, .8);
    color: white;
  }
  .About .imgText h4{
    color: white;
  }
  .About .imgText p{
    font-size: calc(12px + 1vmin);
  }
  .card{
    font-size: larger;
  }
}
 

/* // [ xl ] Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1020px) { 
  .About .first{
    top: 10vh;
    height: 70vh;
    width: 50vw;
    left: 5%;
  }
  .About .second{
    height: 40vh;
    width: 50vw;
    right: 10%;
    top: 45vh;
  }
  .About .imgText{
    z-index: 1;
    width: 55vw;
    height: 40vh;
    top: 15vh;
    left:40%;
  }
  .About .imgText h4{
    color: white;
  }
  .About .imgText p{
    font-size: calc(12px + 1vmin);
  }
  .card{
    font-size: larger;
  }
  .tegan_card{
    max-width: 75vw;
  }
}

/* 
@media(min-width: 1200px){
  .About{
    height: 150vh;
  }
  .About .first{
    top: 50px;
    height: 650px;
    width: 450px;
    left: 15%;
  }
  .About .second{
    visibility: visible;
    background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624899187/skin_collective/amanda-dalbjorn-fvInY-Gh7sc-unsplash_xgxmym.jpg");
    background-size: cover;
    height: 400px;
    width: 400px;
    right: 20%;
    top: 425px;
  }
  .About .imgText{
    z-index: 1;
    width: 500px;
    height: 400px;
    top: 100px;
    right: 10%;
    background-color: rgba(47, 70, 85, .8);
    color: white;
  }
  .About .imgText h4{
    color: white;
  }

}
@media(min-width: 1300px){
  .About .first{
    top: 50px;
    height: 650px;
    width: 450px;
    left: 15%;
  }
  .About .second{
    visibility: visible;
    background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624899187/skin_collective/amanda-dalbjorn-fvInY-Gh7sc-unsplash_xgxmym.jpg");
    background-size: cover;
    height: 450px;
    width: 450px;
    right: 25%;
    top: 425px;
  }
  .About .imgText{
    z-index: 1;
    width: 600px;
    height: 400px;
    top: 100px;
    right: 10%;
    background-color: rgba(47, 70, 85, .8);
    color: white;
  }
  .About .imgText h4{
    color: white;
  }
}
@media(min-width: 1400px){
  .About .first{
    top: 50px;
    height: 650px;
    width: 450px;
    left: 20%;
  }
  .About .second{
    visibility: visible;
    background-image: url("https://res.cloudinary.com/legz444/image/upload/v1624899187/skin_collective/amanda-dalbjorn-fvInY-Gh7sc-unsplash_xgxmym.jpg");
    background-size: cover;
    height: 500px;
    width: 500px;
    right: 20%;
    top: 425px;
  }
  .About .imgText{
    z-index: 1;
    width: 500px;
    height: 400px;
    top: 100px;
    right: 15%;
    background-color: rgba(47, 70, 85, .8);
    color: white;
  }
  .About .imgText h4{
    color: white;
  }
} */

@font-face {
  font-family: "Aubrey"; 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot"); 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot?#iefix") 
  format("embedded-opentype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff") format("woff"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.svg#Aubrey") format("svg"); 
}
  *::after,
  *::before{
      margin: 0;
      padding: 0%;
      box-sizing: inherit;
  }
  body{
  font-size: 10;
  }
  body {
     box-sizing: border-box;
      }

body{
  font-family: 'Darker Grotesque', sans-serif;
    
}
.store{
  display: -webkit-flex;
  display: flex;
  background-color: rgb(223 230 233);
}

/* Sidebar Styles */
.sidebar{
  margin-top: 100px;
  width: 15vw;
  height: 100vh;
}

/* Product Grid Styles */
.product_grid{
  background-color: white;
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  display: grid;
  width: 100vw;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
}
.product{
  text-align: center;
  border: solid rgb(223 230 233) .5px;
  box-shadow: -5px 5px 5px -5px black ;
  padding-bottom: 5px;
}
.img_container{
  position:relative;
}
.product_img{
  width: 200px;
  height: 200px;
}
.product_img_overlay {
  position: absolute;
  top: 50;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(223 230 233);
}

.img_container:hover .product_img_overlay {
  opacity: .9;
}

.overlay_txt {
  color: black;
  font-size: .6em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.product_title{
  font-size: 1em;
}
.product_price{
  font-size: 1em;
  font-style: italic;
}
.product_option{
  font-size: .8em;
}
.product_option input{
  max-width: 20%;
}
.product_buy_btn{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.product_buy_btn:hover{
  background-color: rgb(47 70 85);
  color: white;
}

/* Cart Styles */
.Cart{
  margin-top: 100px;
  background-color: white;
  padding-top: 25px;
}
.Cart_h2{
  font-size: 1.5em;
  text-align: center;
}
.Cart__checkout{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.Cart__checkout:hover{
  background-color: rgb(47 70 85);
  color: white;
}

.Cart__footer{
  background-color: white;
}

/* line Items in Cart */
.line_item{
  margin-bottom: 50px;
}
.Line-item__content-row{
  text-align: center;
}
.Line-item__remove{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.Line-item__remove:hover{
  background-color: rgb(47 70 85);
  color: white;
}


/* Media queries */

@media (min-width: 319px){


}
@media (min-width: 400px){

}

/* // [ sm ] Small devices (landscape phones, 576px and up) */ 
@media (min-width: 565px) {

}
/* // [ md ] Medium devices (tablets, 768px and up) */
@media (min-width: 765px) {

  }
@media (min-width: 800px){

 }
/* // [ lg ] Large devices (desktops, 992px and up) */
@media (min-width: 900px) {
  
}
 

/* // [ xl ] Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1020px) { 

}

body {
  margin: 0;
  font-family: 'Fjord One', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

