@import url('https://fonts.cdnfonts.com/css/aubrey');

@font-face {
  font-family: "Aubrey"; 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot"); 
  src: url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.eot?#iefix") 
  format("embedded-opentype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.woff") format("woff"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/f109f08852742e27f747f260abdf6fb8.svg#Aubrey") format("svg"); 
}
  *::after,
  *::before{
      margin: 0;
      padding: 0%;
      box-sizing: inherit;
  }
  body{
  font-size: 10;
  }
  body {
     box-sizing: border-box;
      }

body{
  font-family: 'Darker Grotesque', sans-serif;
    
}
.store{
  display: flex;
  background-color: rgb(223 230 233);
}

/* Sidebar Styles */
.sidebar{
  margin-top: 100px;
  width: 15vw;
  height: 100vh;
}

/* Product Grid Styles */
.product_grid{
  background-color: white;
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  display: grid;
  width: 100vw;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
}
.product{
  text-align: center;
  border: solid rgb(223 230 233) .5px;
  box-shadow: -5px 5px 5px -5px black ;
  padding-bottom: 5px;
}
.img_container{
  position:relative;
}
.product_img{
  width: 200px;
  height: 200px;
}
.product_img_overlay {
  position: absolute;
  top: 50;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(223 230 233);
}

.img_container:hover .product_img_overlay {
  opacity: .9;
}

.overlay_txt {
  color: black;
  font-size: .6em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.product_title{
  font-size: 1em;
}
.product_price{
  font-size: 1em;
  font-style: italic;
}
.product_option{
  font-size: .8em;
}
.product_option input{
  max-width: 20%;
}
.product_buy_btn{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.product_buy_btn:hover{
  background-color: rgb(47 70 85);
  color: white;
}

/* Cart Styles */
.Cart{
  margin-top: 100px;
  background-color: white;
  padding-top: 25px;
}
.Cart_h2{
  font-size: 1.5em;
  text-align: center;
}
.Cart__checkout{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.Cart__checkout:hover{
  background-color: rgb(47 70 85);
  color: white;
}

.Cart__footer{
  background-color: white;
}

/* line Items in Cart */
.line_item{
  margin-bottom: 50px;
}
.Line-item__content-row{
  text-align: center;
}
.Line-item__remove{
  background-color: rgb(183 201 224);
  color: rgb(22 38 46) ;
  border: none ;
  border-radius: 25px;
  font-family: 'Darker Grotesque', sans-serif;
  margin: 5px;
  font-size: 1em ;
  transition-duration: 0.4s;
}
.Line-item__remove:hover{
  background-color: rgb(47 70 85);
  color: white;
}


/* Media queries */

@media (min-width: 319px){


}
@media (min-width: 400px){

}

/* // [ sm ] Small devices (landscape phones, 576px and up) */ 
@media (min-width: 565px) {

}
/* // [ md ] Medium devices (tablets, 768px and up) */
@media (min-width: 765px) {

  }
@media (min-width: 800px){

 }
/* // [ lg ] Large devices (desktops, 992px and up) */
@media (min-width: 900px) {
  
}
 

/* // [ xl ] Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1020px) { 

}
